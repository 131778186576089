import React from "react"
import { bool, func } from 'prop-types';

import "../scss/burger.scss"


const Burger = ({ open, setOpen }) => {
  //Stop Body Scroll
  const handleScroll = () => {
    if(window.matchMedia("(max-width: 600px)").matches){
      document.body.style.overflow === 'hidden'
      ? document.body.style.overflow = 'auto'
      : document.body.style.overflow = 'hidden';
    }
    else{
      document.body.style.overflow = 'auto';
    }
  }

  return(
      <button className={open ? "burger burger--open" : "burger"} open={open} onClick={() => {handleScroll(); setOpen(!open)}}>
        <div className="burger__inner"></div>
      </button>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};



export default Burger
