import React from "react"
import Header from "../components/header"

import "normalize.css"
import "../scss/layout.scss"

export default function Layout({ children }) {
  return (
    <>
    <main>
      <div className="wrapper">
        <Header></Header>
        {children}
      </div>
    </main>
    </>
  )
}
