import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Burger from "../components/burger"
import Code from "../icons/code.svg";

import "../scss/header.scss"
import "../scss/burger.scss"


const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false);

  const handleScroll = () => {
    if(window.matchMedia("(max-width: 600px)").matches){
      document.body.style.overflow === 'hidden'
      ? document.body.style.overflow = 'auto'
      : document.body.style.overflow = 'hidden';
    }
    else{
      document.body.style.overflow = 'auto';
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, [])

  const onResize = () => {
    if(window.matchMedia("(min-width: 600px)").matches && document.body.style.overflow === 'hidden'){
        document.body.style.overflow = 'auto'
        setOpen(false);
    }
  }

  return(
    <header className="header">
      <a href="/" className="header__logo" rel="noopener noreferrer">Khoi<br />Nguyen</a>
      <nav className={ open ? "header__nav header__nav--open" : "header__nav" }>
        <ul>
          <li>
            <a href="#featured" onClick={() => {setOpen(!open); handleScroll()}}>Featured</a>
          </li>
          <li>
            <a href="#archive" onClick={() => {setOpen(!open); handleScroll()}}>Archiv</a>
          </li>
          <li>
            <a href="#contact" onClick={() => {setOpen(!open); handleScroll()}}>Kontakt</a>
          </li>
          <li>
            <a className="header__button" href="https://github.com/khoilerspoiler" target="_blank" rel="nofollow noopener noreferrer" aria-label="External Link">GitHub<Code /></a>
          </li>
        </ul>
      </nav>
      <Burger open={open} setOpen={setOpen}/>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
